import Footer from "./components/layout/Footer";
import Navbar from "./components/layout/Navbar";

export default function TermsOfService(props: any) {
  return (
    <div>
      <Navbar isLoggedIn={false}></Navbar>
      <div className="container" style={{ marginTop: "6rem" }}>
        <div className="card">
          <div className="card-body">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h1 className="mt-5">Terms of Service</h1>
                  <p>
                    Last updated: <strong>September 2024</strong>
                  </p>

                  <h2 className="mt-4">1. Acceptance of Terms</h2>
                  <p>
                    By accessing and using StockOverflow, you agree to comply
                    with and be bound by these Terms of Service.
                  </p>

                  <h2 className="mt-4">2. Use of Our Service</h2>
                  <p>
                    You agree to use our service in compliance with applicable
                    laws and regulations. You will not misuse our platform to
                    conduct illegal activities.
                  </p>

                  <h2 className="mt-4">3. Account Registration</h2>
                  <p>
                    To access certain features, you may be required to create an
                    account. You are responsible for maintaining the
                    confidentiality of your account credentials and are fully
                    responsible for any activity that occurs under your account.
                  </p>

                  <h2 className="mt-4">4. Intellectual Property</h2>
                  <p>
                    All content, logos, and trademarks displayed on
                    StockOverflow are the property of StockOverflow and its
                    affiliates. You may not reproduce or redistribute this
                    content without our express written permission.
                  </p>

                  <h2 className="mt-4">5. Termination of Use</h2>
                  <p>
                    We reserve the right to terminate or suspend your access to
                    our service for violating these terms or engaging in
                    prohibited activities.
                  </p>

                  <h2 className="mt-4">6. Limitation of Liability</h2>
                  <p>
                    StockOverflow is provided on an "as is" basis. We are not
                    liable for any losses, damages, or missed opportunities
                    arising from the use of our platform, including but not
                    limited to financial loss.
                  </p>

                  <h2 className="mt-4">7. Changes to the Terms</h2>
                  <p>
                    We may update these Terms of Service from time to time. We
                    will notify you of any changes by posting the revised terms
                    on our website. Your continued use of StockOverflow after
                    such changes constitutes your acceptance of the new Terms.
                  </p>

                  <h2 className="mt-4">8. Contact Information</h2>
                  <p>
                    If you have any questions about these Terms, please contact
                    us at{" "}
                    <a href="mailto:contact.stockoverflow@gmail.com">
                      contact.stockoverflow@gmail.com
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
